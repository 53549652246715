<template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="login-container p-5 shadow bg-white rounded">
      <div class="text-center mb-4">
        <img src="../assets/logo.png" alt="Logo" class="logo mb-3" />
        <h3>Login</h3>
      </div>
      <form @submit.prevent="loginUser">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            id="email"
            v-model="email"
            required
            class="form-control"
          />
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            id="password"
            v-model="password"
            required
            class="form-control"
          />
        </div>

        <button type="submit" class="btn btn-primary w-100">Login</button>
      </form>

      <div class="text-center mt-3">
        <p>Don't have an account? 
          <router-link to="/register">Register here</router-link>
        </p>
      </div>

      <div v-if="message" class="alert mt-3" :class="messageClass">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs'; // Import qs for URL encoding

export default {
  data() {
    return {
      email: '',
      password: '',
      message: '',
      messageClass: ''
    };
  },
  methods: {
    async loginUser() {
      try {
        const response = await axios.post(
          'https://mjr.vantechsolutions.co.za/backend/login_user.php',
          qs.stringify({
            email: this.email,
            password: this.password
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );

        if (response.data.success) {
          this.message = 'Login successful!';
          this.messageClass = 'alert-success';
          // Redirect after login
          this.$router.push('/home');
        } else {
          this.message = response.data.message || 'Invalid email or password.';
          this.messageClass = 'alert-danger';
        }
      } catch (error) {
        console.error('Login error:', error);
        this.message = 'Login failed. Please try again.';
        this.messageClass = 'alert-danger';
      }
    }
  }
};
</script>
