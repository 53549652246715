import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '@/components/LoginPage.vue';
import RegisterPage from '@/components/RegisterPage.vue';
import UserHome from '@/components/UserHome.vue'; // Update to import UserHome.vue

const routes = [
  {
    path: '/',
    redirect: '/login'  // Default route redirects to the login page
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/home',
    name: 'Home',
    component: UserHome  // Update the Home route to use UserHome.vue
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
