import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // Import the router
import './assets/global.css';  // Import global CSS for the background
import './assets/styles.css';  // Make sure this path is correct

// Import Bootstrap 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';


createApp(App)
  .use(router) // Use the router
  .mount('#app');