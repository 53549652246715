<template>
  <div class="container d-flex justify-content-center align-items-center vh-100">
    <div class="login-container p-5 shadow bg-white rounded">
        <!-- Logo on top of the form -->
        <div class="text-center mb-4">
          <img src="../assets/logo.png" alt="Logo" class="logo mb-3">
          <h3>Register</h3>
        </div>

      <form @submit.prevent="registerUser">
        <div class="mb-3">
          <label for="username" class="form-label">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            v-model="username"
            required
            class="form-control"
          />
        </div>

        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            v-model="email"
            required
            class="form-control"
          />
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            v-model="password"
            required
            class="form-control"
          />
        </div>

        <div class="mb-3">
          <label for="role" class="form-label">Role</label>
          <select
            id="role"
            name="role"
            v-model="role"
            required
            class="form-select"
          >
            <option value="user">User</option>
            <option value="admin">Coach</option>
          </select>
        </div>

        <button type="submit" class="btn btn-primary w-100">Register</button>
      </form>

      <div class="text-center mt-3">
        <p>Already have an account? 
          <router-link to="/login">Login here</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs'; // Import qs for URL encoding
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      role: 'user',
      message: ''
    };
  },
  methods: {
  async registerUser() {
    try {
      const response = await axios.post('https://mjr.vantechsolutions.co.za/backend/register_user.php',
        qs.stringify({
          username: this.username,
          email: this.email,
          password: this.password,
          role: this.role
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      if (response.data.success) {
        this.message = response.data.message || 'Registration successful!';
      } else {
        this.message = response.data.message || 'Registration failed. Please try again.';
      }
    } catch (error) {
      console.error('Error during registration:', error);
      this.message = 'Registration failed. Please try again later.';
    }
  }
}
};
</script>
