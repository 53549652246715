<template>
    <div class="container text-center mt-5">
      <h1>Welcome to the User Home Page!</h1>
      <p>You have successfully logged in.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'UserHome'
  };
  </script>
  
  <style scoped>
  /* Add custom styles if needed */
  </style>
  